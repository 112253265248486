.sidebar {
  height: 100%;
  width: 140px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-top: 20px;
  background-color: lightgray;
}

.sidebar a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  display: block;
  color: #111;
  font-size: larger;
}

.main {
  position: relative;
  top: 0;
  left: 140px;
}
