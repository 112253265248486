.platformAdminDetails {
    display: 'block';
    border: solid 2px;
    border-radius: 2px;
    margin: 6px 8px 6px 16px;
    padding: 6px 8px 6px 16px;
    width: 80%;
}

.platformAdminDetails p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}